// Generated by Framer (b2eeca0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {ErbvQxEho: {hover: true}};

const cycleOrder = ["ErbvQxEho"];

const variantClassNames = {ErbvQxEho: "framer-v-1ge1fgf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; title?: string; newTab?: boolean }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "ErbvQxEho", link: FuLWFDk6f, title: bzu_bZZXt = "hello@emptyshelf.design", newTab: LURTURWaj = true, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ErbvQxEho", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-zdjBa", classNames)} style={{display: "contents"}}>
<Link href={FuLWFDk6f} openInNewTab={LURTURWaj}><motion.a {...restProps} className={`${cx("framer-1ge1fgf", className)} framer-6qbzj4`} data-framer-name={"Unselected"} layoutDependency={layoutDependency} layoutId={"ErbvQxEho"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"ErbvQxEho-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TWFucm9wZS1yZWd1bGFy", "--framer-font-family": "\"Manrope\", serif", "--framer-font-size": "18px", "--framer-line-height": "1.4em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>hello@emptyshelf.design</motion.p></React.Fragment>} className={"framer-1xtf57p"} fonts={["GF;Manrope-regular"]} layoutDependency={layoutDependency} layoutId={"PKMZHnmxg"} style={{"--extracted-r6o4lv": "var(--token-d85d8d2e-a90b-41e9-8852-32af98456a8d, rgb(2, 10, 54)) ", "--framer-link-hover-text-color": "var(--token-8856508d-3cdd-4b9d-93c5-9410ff7516dc, rgb(32, 71, 244)) /* {\"name\":\"Primary\"} */", "--framer-link-text-color": "var(--token-d85d8d2e-a90b-41e9-8852-32af98456a8d, rgb(2, 10, 54))", "--framer-paragraph-spacing": "0px"}} text={bzu_bZZXt} transition={transition} variants={{"ErbvQxEho-hover": {"--extracted-r6o4lv": "var(--token-c30f424b-1ccb-4d94-827a-c7e5c524df2e, rgb(138, 138, 138)) "}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-zdjBa [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zdjBa .framer-6qbzj4 { display: block; }", ".framer-zdjBa .framer-1ge1fgf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-zdjBa .framer-1xtf57p { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", ".framer-zdjBa .framer-v-1ge1fgf .framer-1ge1fgf { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-zdjBa .framer-1ge1fgf { gap: 0px; } .framer-zdjBa .framer-1ge1fgf > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-zdjBa .framer-1ge1fgf > :first-child { margin-left: 0px; } .framer-zdjBa .framer-1ge1fgf > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 25
 * @framerIntrinsicWidth 213
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ryety_6Bd":{"layout":["auto","auto"]}}}
 * @framerVariables {"FuLWFDk6f":"link","bzu_bZZXt":"title","LURTURWaj":"newTab"}
 */
const FramerCmRg0Z9Ex: React.ComponentType<Props> = withCSS(Component, css, "framer-zdjBa") as typeof Component;
export default FramerCmRg0Z9Ex;

FramerCmRg0Z9Ex.displayName = "Header Link";

FramerCmRg0Z9Ex.defaultProps = {height: 25, width: 213};

addPropertyControls(FramerCmRg0Z9Ex, {FuLWFDk6f: {title: "Link", type: ControlType.Link}, bzu_bZZXt: {defaultValue: "hello@emptyshelf.design", displayTextArea: false, title: "Title", type: ControlType.String}, LURTURWaj: {defaultValue: true, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerCmRg0Z9Ex, [{family: "Manrope", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/CmRg0Z9Ex:default", url: "https://fonts.gstatic.com/s/manrope/v14/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F87jxeN7B.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/manrope/v14/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F87jxeN7B.ttf", weight: "400"}])